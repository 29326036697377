import { appearanceSetting } from "./appearance-settings.js";
import { dbSettings } from "./db-settings.js";

const settingsModule = {
  namespaced: true,
  modules: {
    db: dbSettings,
    appearance: appearanceSetting
  }
};

export default settingsModule;
