import http from "@/http.js";
import { RU, EN, LT } from "@/const/langs.js";
import { LOAD } from "@/const/vehicle-load-types.js";

export default {
  namespaced: true,
  state: {
    loadItems: [],
    vehicleId: 0,
    availableGoods: [],
    goodsSerialNumbers: []
  },
  getters: {
    firstValidationErrorCode: state => {
      // check all titles are set
      const loadItemsWithoutTitle = state.loadItems.filter(x => x.goods.id === 0);
      if (loadItemsWithoutTitle.length != 0) {
        return "load-item-has-no-title";
      }
      // check serial numbers count is right
      const loadItemsWithIncorrectSerialNumbers = state.loadItems.filter(
        x => x.goods.hasSerialNumber && x.serialNumbers.length !== x.count
      );
      if (loadItemsWithIncorrectSerialNumbers.length != 0) {
        return "load-item-has-incorrect-serial-number-count";
      }
      return null;
    },
    goodsInfoWithSerialNumbersIds: state => {
      return state.availableGoods.filter(x => x.hasSerialNumber).map(x => x.id);
    }
  },
  mutations: {
    setLoadItems(state, { vehicleId, defaultLoadItems }) {
      // initial values from template
      state.loadItems = defaultLoadItems.map(x => {
        return {
          goods: {
            id: x.goods.id,
            title: x.goods.title,
            hasSerialNumber: x.goods.hasSerialNumber,
            code: x.goods.code,
            imageId: x.goods.imageId
          },
          count: 0,
          minQuantity: x.minQuantity,
          quantity: x.quantity,
          serialNumbers: [],
          unit: x.unit
        };
      });
      state.vehicleId = vehicleId;
    },
    updateGoodsTitle(state, { oldGoodsIndex, newGoods }) {
      let itemToChange = state.loadItems[oldGoodsIndex];
      itemToChange.goods = newGoods;
    },
    addEmptyItem(state) {
      state.loadItems.push({
        goods: {
          id: 0,
          title: { [RU]: "", [EN]: "", [LT]: "" },
          hasSerialNumber: false,
          code: ""
        },
        minQuantity: "",
        quantity: "",
        serialNumbers: [],
        count: 0,
        unit: ""
      });
    },
    removeGoods(state, goodsIndex) {
      state.loadItems.splice(goodsIndex, 1);
    },
    updateCount(state, { goodsIndex, newValue }) {
      state.loadItems[goodsIndex].count = newValue;
    },
    updateSerialNumbers(state, { goodsIndex, newValue }) {
      state.loadItems[goodsIndex].serialNumbers = newValue;
    },
    setAvailableGoods(state, availableGoods) {
      state.availableGoods = availableGoods;
    },
    addSerialNumbers(state, goodsSerialNumbers) {
      // for now we load them only from setInitialStateForVehicle, so no need to check existing values
      state.goodsSerialNumbers.push(goodsSerialNumbers);
    },
    clearState(state) {
      state.loadItems = [];
      state.vehicleId = 0;
      state.availableGoods = [];
      state.goodsSerialNumbers = [];
    }
  },
  actions: {
    getVehicles() {
      return http.get("api/vehicle-load/all");
    },
    loadVehicle(state, vm) {
      return http.post("api/vehicle-load/set", vm);
    },
    uploadVehicleLoad() {
      var vm = {
        vehicleId: this.state.vehicleLoad.vehicleId,
        loadItems: this.state.vehicleLoad.loadItems.map(x => {
          return {
            count: x.count,
            goodsId: x.goods.id,
            serialNumbers: x.serialNumbers
          };
        }),
        type: LOAD
      };
      return http.post("api/vehicle-load/set", vm);
    },
    setInitialStateForVehicle(context, vehicleId) {
      // clear state from previous work
      context.commit("clearState");

      http.get("api/goods-group/groups/info").then(resp => {
        this.state.goodsGroups = resp.data;
      });

      // Load default template, available goods, available serial numbers for available goods
      let defaultTemplateRequest = http.get(`api/template/for-vehicle/${vehicleId}`).then(resp => {
        if (resp.data.goodsTemplates) {
          context.commit("setLoadItems", {
            vehicleId: vehicleId,
            defaultLoadItems: resp.data.goodsTemplates
          });
        } else {
          context.commit("setLoadItems", { vehicleId: vehicleId, defaultLoadItems: [] });
        }
      });

      let goodsAndSerialNumbersRequest = http.get("api/goods/all").then(resp => {
        context.commit("setAvailableGoods", resp.data);
        let availableSerialNumberRequests = [];
        context.getters.goodsInfoWithSerialNumbersIds.forEach(id => {
          availableSerialNumberRequests.push(
            http.get(`api/goods/${id}/serial-numbers/available`).then(resp => {
              context.commit("addSerialNumbers", resp.data);
            })
          );
        });
        if (availableSerialNumberRequests.length > 0) {
          return Promise.all(availableSerialNumberRequests);
        }
      });

      return Promise.all([defaultTemplateRequest, goodsAndSerialNumbersRequest]);
    },
    updateGoodsGroupTitle(context, { newValue, locale }) {
      if (newValue !== null) {
        http.get(`api/goods/get-goods-by-group/${newValue}/locale/${locale}`).then(resp => {
          context.commit("setAvailableGoods", resp.data);
        });
      } else
        http.get("api/goods/all").then(resp => {
          context.commit("setAvailableGoods", resp.data);
        });
    },
    getVehicleLoadItems(state, vehicleId) {
      return http.get(`api/vehicle-load/load-items/${vehicleId}`);
    },
    getVehiclesWithLoadedItems() {
      return http.get("api/vehicle-load/load-items/all");
    }
  }
};
