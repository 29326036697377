<style scoped>
.lang-img {
  width: 30px;
  height: 30px;
}
.flex-container {
  display: flex;
}
.nav-item {
  width: 90%;
}
.settings-img {
  width: 30px;
  filter: invert(75%);
}
.loginImage {
  height: 30px;
}
.loginImage {
  filter: invert(75%);
}
</style>

<template>
  <div>
    <loginModal ref="loginModal" />
    <new-incident-modal
      :vehicleOptions="allVehicles"
      :drivers="drivers"
      :incidentResponsibleUsers="incidentResponsibleUsers"
      :vehicle="vehicle"
      v-if="user != null"
    />
    <b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand>
        <img src="/images/main_logo.png" @click="redirectUserToHisPage" class="cursor-pointer" />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <navbar-available-actions></navbar-available-actions>
        <b-navbar-nav class="ml-auto">
          <div>
            <img
              :title="$t('navbar-tooltip.add-issue')"
              src="/images/exclamation-circle-solid.svg"
              class="toolbarImage settings-img cursor-pointer ml-2 mr-3 pt-2"
              @click="showNewIncidentModal"
              v-if="user != null"
            />
          </div>
          <div class="d-inline-flex mr-3">
            <img :src="langImgUrl()" class="lang-img m-2 mt-2" />
            <b-nav-item-dropdown right :text="showSelectedLanguage()" :title="$t('navbar-tooltip.change-language')">
              <b-dropdown-item href="#" @click="changeLanguage('en')">
                <img src="/images/en.svg" class="lang-img" />
                {{ languageVariants.en }}
              </b-dropdown-item>
              <b-dropdown-item href="#" @click="changeLanguage('ru')">
                <img src="/images/ru.svg" class="lang-img" />
                {{ languageVariants.ru }}
              </b-dropdown-item>
              <b-dropdown-item href="#" @click="changeLanguage('lt')">
                <img src="/images/lt.svg" class="lang-img" />
                {{ languageVariants.lt }}
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </div>
          <div v-if="showSettingsIcon">
            <router-link to="/settings">
              <img
                class="settings-img ml-2 mr-3 pt-2"
                src="/images/cog-solid.svg"
                :title="$t('navbar-tooltip.settings')"
              />
            </router-link>
          </div>
          <div class="ml-2 mr-3">
            <b-nav-item v-if="user != null" @click="onUserNavItemClick">
              <div class="flex-container">
                <img src="/images/user-circle.svg" class="loginImage" />
                <b-nav-text class="mx-2 p-0">{{ user }}</b-nav-text>
              </div>
            </b-nav-item>
            <b-nav-item v-else>
              <img src="/images/user-circle-regular.svg" class="loginImage" @click="onLoginClick" />
            </b-nav-item>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import NewIncidentModal from "@/components/incident/NewIncidentModal.vue";
import LoginModal from "@/components/LoginModal.vue";
import NavbarAvailableActions from "@/components/navbar/NavbarAvailableActions.vue";

import api from "@/services/api.js";
import auth from "@/services/auth.js";
import { getCurrentLocale, setLocale } from "@/services/locale.js";
import eventBus from "@/services/event-bus.js";
import { mapActions, mapMutations } from "vuex";

import { LOGIN, LOGOUT, SHOW_LOGIN_POPUP, OPEN_NEW_INCIDENT_MODAL } from "@/const/events.js";
import { DRIVER } from "@/const/user-role.js";
import redirectUserToHisPageMixin from "@/mixins/redirect-user-to-his-page.js";
import redirectWithChecksMixin from "@/mixins/redirect-with-check.js";

export default {
  mixins: [redirectUserToHisPageMixin, redirectWithChecksMixin],
  data() {
    return {
      user: null,
      selectedLanguage: getCurrentLocale() || "en",
      languageVariants: {
        en: "English",
        ru: "Русский",
        lt: "Lietuvių"
      },
      allVehicles: [],
      vehicle: null,
      drivers: [],
      incidentResponsibleUsers: []
    };
  },
  components: {
    loginModal: LoginModal,
    "new-incident-modal": NewIncidentModal,
    "navbar-available-actions": NavbarAvailableActions
  },
  computed: {
    showSettingsIcon() {
      return this.$store.state.userSettings.userInfo.role !== DRIVER;
    }
  },
  methods: {
    ...mapActions(["vehicle/getAll", "vehicleLoadAudit/getDriversCurrentAcceptedVehicle"]),
    ...mapMutations(["userSettings/setUserRole"]),
    changeLanguage(value) {
      setLocale(this.$i18n, value);
      this.selectedLanguage = value;
    },
    langImgUrl() {
      if (this.selectedLanguage === "en") {
        return "/images/en.svg";
      } else if (this.selectedLanguage === "ru") {
        return "/images/ru.svg";
      } else if (this.selectedLanguage === "lt") {
        return "/images/lt.svg";
      }
    },
    showSelectedLanguage() {
      if (this.selectedLanguage === "en") {
        return this.languageVariants.en;
      } else if (this.selectedLanguage === "ru") {
        return this.languageVariants.ru;
      } else if (this.selectedLanguage === "lt") {
        return this.languageVariants.lt;
      }
    },
    onLogin() {
      this.user = auth.userName();
      this["userSettings/setUserRole"](auth.userRole());
    },
    onLoginClick() {
      this.showLoginModal();
    },
    onLogout() {
      this.user = null;
      this["userSettings/setUserRole"](null);
    },
    showLoginModal() {
      this.$refs["loginModal"].show();
    },
    onUserNavItemClick() {
      this.redirectToRoute("my-settings");
    },
    showNewIncidentModal() {
      this.vehicle = { id: null, label: null };
      if (auth.userRole() === DRIVER) {
        this["vehicleLoadAudit/getDriversCurrentAcceptedVehicle"]()
          .then(resp => {
            if (resp.data != "") this.vehicle = { id: resp.data.id, label: resp.data.registrationNumber };
          })
          .finally(() => this.$bvModal.show("new-incident-modal"));
      } else {
        this.$bvModal.show("new-incident-modal");
      }

      api.getAllUsers().then(resp => {
        this.drivers = resp.data
          .filter(x => x.role == DRIVER)
          .map(x => {
            return { id: x.id, userName: x.userName };
          });

        this.incidentResponsibleUsers = resp.data
          .filter(x => x.role != DRIVER)
          .map(x => {
            return { id: x.id, userName: x.userName };
          });
      });

      api.getVehiclesRegistrationNumbers().then(resp => {
        this.allVehicles = resp.data.map(x => {
          return { id: x.vehicleId, label: x.registrationNumber };
        });
      });
    }
  },
  mounted() {
    this.$bus.on(LOGIN, this.onLogin);
    this.$bus.on(LOGOUT, this.onLogout);
    this.$bus.on(OPEN_NEW_INCIDENT_MODAL, this.showNewIncidentModal);
    eventBus.$on(SHOW_LOGIN_POPUP, this.showLoginModal);
    this.onLogin();
  },
  beforeDestroy() {
    this.$bus.off(LOGIN, this.onLogin);
    this.$bus.off(LOGOUT, this.onLogout);
    this.$bus.off(OPEN_NEW_INCIDENT_MODAL, this.showNewIncidentModal);
    eventBus.$off(SHOW_LOGIN_POPUP, this.showLoginModal);
  }
};
</script>
