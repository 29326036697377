import api from "@/services/api.js";
import Vue from "vue";
import { getCurrentLocale } from "@/services/locale.js";

let sameDayOfPreviousMonth = new Date();
sameDayOfPreviousMonth.setMonth(sameDayOfPreviousMonth.getMonth() - 1);
let today = new Date();

function getSearchParams(state) {
  let filtersCut = { ...state.filters };
  filtersCut.goods = filtersCut.goods.map(x => x.id);
  filtersCut.users = filtersCut.users.map(x => x.userId);
  filtersCut.plates = filtersCut.plates.map(x => x.vehicleId);

  return {
    ...filtersCut,
    offset: state.pagination.limit * (state.pagination.currentPage - 1),
    limit: state.pagination.limit,
    sortBy: state.sort.by,
    isSortDesc: state.sort.isDesc,
    locale: getCurrentLocale()
  };
}

export default {
  namespaced: true,
  state: {
    transactions: [],
    isLoading: false,
    filters: {
      timeFrom: sameDayOfPreviousMonth.toISOString().slice(0, 10),
      timeTo: today.toISOString().slice(0, 10),
      goods: [],
      users: [],
      plates: [],
      event: "",
      user: "",
      vehicle: "",
      goodsTitle: ""
    },
    pagination: {
      limit: 20,
      offset: 0,
      currentPage: 1,
      totalCount: 0
    },
    sort: {
      by: "",
      isDesc: false
    }
  },
  getters: {
    transactionsByDriver: state => driverId => {
      const transactions = state.transactions.find(x => x.driver.id === driverId);

      return transactions ? transactions.transactions : [];
    },
    filters: state => state.filters,
    pagination: state => state.pagination,
    transactions: state => state.transactions
  },
  mutations: {
    setTransactions(state, transactions) {
      state.transactions = transactions;
    },
    setLoadingState(state, isLoading) {
      state.isLoading = isLoading;
    },
    setSelectedStateByDriver(state, { driverIds, isSelected }) {
      state.transactions.forEach((x, i) => {
        if (driverIds.includes(x.driver.id)) {
          x.selected = isSelected;
          Vue.set(state.transactions, i, x);
        }
      });
    },
    clear(state) {
      state.transactions = [];
      state.isLoading = false;
    },
    setSort(state, { sortBy, sortDesc }) {
      state.sort.by = sortBy;
      state.sort.isDesc = sortDesc;
    },
    setCurrentPage(state, payload) {
      state.pagination.currentPage = payload;
    },
    setFilter(state, { property, value }) {
      if (state.filters.hasOwnProperty(property)) {
        state.filters[property] = value;
      }
    }
  },
  actions: {
    loadTransactions({ state, commit }) {
      state.transactions = [];

      return api
        .getLoadHistory(getSearchParams(state))
        .then(resp => {
          commit("setTransactions", resp.data.items || []);
          state.pagination.totalCount = resp.data.totalCount;
        })
        .finally(() => commit("setLoadingState", false));
    },
    setFilter({ commit, dispatch }, { property, value }) {
      commit("setFilter", { property, value });
      commit("setCurrentPage", 1);

      return dispatch("loadTransactions");
    },
    setCurrentPage({ state, dispatch }, page) {
      state.pagination.currentPage = page;

      return dispatch("loadTransactions");
    },
    setSort({ commit, dispatch }, ctx) {
      commit("setSort", ctx);
      commit("setCurrentPage", 1);
      return dispatch("loadTransactions");
    },
    exportLoadHistory({ state }) {
      return api.exportLoadHistory(getSearchParams(state));
    }
  }
};
