import Vue from "vue";
import Vuex from "vuex";

import { goods } from "./modules/goods.js";
import { goodsGroup } from "./modules/goods-group.js";
import { vehicle } from "./modules/vehicle.js";
import { vehicleLoadAudit } from "./modules/vehicle-load-audit.js";
import { vehicleCategory } from "./modules/vehicle-category.js";
import { vehicleType } from "./modules/vehicle-type.js";
import { dictionary } from "./modules/dictionary.js";
import { incidents } from "./modules/incidents.js";
import { issueCategories } from "./modules/issue-categories";
import { i18n } from "./modules/i18n.js";
import { template } from "./modules/template.js";
import vehicleLoad from "./modules/vehicle-load.js";
import vehicleEvents from "./modules/vehicle-events.js";
import userSettings from "./modules/user-settings.js";
import { users } from "./modules/users.js";
import { purityCheck } from "./modules/purity-check.js";
import settings from "./modules/settings/index.js";
import transactions from "./modules/transactions.js";
import transactionPages from "./modules/transaction-pages.js";
import soldItemsTransactions from "./modules/sold-items-transactions.js";
import currentLoad from "./modules/current-load.js";
import currentLoads from "./modules/current-loads.js";
import customChecks from "./modules/custom-checks.js";
import vehicleHistory from "./modules/vehicle-history.js";
import vehicleIncidents from "./modules/vehicle-incidents.js";
import vehicleGoods from "./modules/vehicle-goods.js";
import userSales from "./modules/user-sales.js";
import events from "./modules/events.js";
import loadHistoryTransactions from "./modules/load-history-transactions.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    goods,
    goodsGroup,
    vehicle,
    dictionary,
    i18n,
    incidents,
    issueCategories,
    template,
    vehicleLoad,
    vehicleEvents,
    userSettings,
    users,
    userSales,
    purityCheck,
    settings,
    vehicleLoadAudit,
    transactions,
    transactionPages,
    soldItemsTransactions,
    vehicleCategory,
    vehicleType,
    currentLoad,
    currentLoads,
    customChecks,
    vehicleHistory,
    vehicleIncidents,
    vehicleGoods,
    events,
    loadHistoryTransactions
  },
  state: {
    availableTableHeight() {
      return window.innerHeight - document.getElementById("navigation-bar").clientHeight + "px";
    }
  }
});
