import axios from "axios";
import auth from "@/services/auth.js";
import { SHOW_LOGIN_POPUP } from "@/const/events.js";
import eventBus from "@/services/event-bus.js";

const http = axios.create({
  baseURL: "https://app.ollex.lt"
});

http.interceptors.request.use(function(config) {
  config.headers.Authorization = "Bearer " + auth.accessToken() || "";
  return config;
});

let areTokensRefreshing = false;
let awaitingRequests = [];
const refreshTokensUrl = "api/user/refresh-tokens";

function refreshTokensAndRepeat(failedRequest) {
  if (!areTokensRefreshing) {
    areTokensRefreshing = true;
    let refreshTokenRequestVm = auth.getTokens();
    http
      .post(refreshTokensUrl, refreshTokenRequestVm)
      .then(resp => {
        // we've got new login info. Let's store it and continue to work
        auth.set(resp.data);
        // run all awaiting requests
        awaitingRequests.forEach(x => x());
      })
      .catch(() => {
        // we haven't got new tokens, seems like our refresh token is invalid, let's forget about it and relogin
        auth.clear();
        eventBus.$emit(SHOW_LOGIN_POPUP);
      })
      .finally(() => {
        awaitingRequests = [];
        areTokensRefreshing = false;
      });
  }

  const originalRequest = failedRequest.config;
  // we promise to rerun request later
  return new Promise(resolve => {
    awaitingRequests.push(() => resolve(http.request(originalRequest)));
  });
}

http.interceptors.response.use(undefined, error => {
  if (error.response.status === 401 && error.config.url != refreshTokensUrl) {
    // let's check if we have refresh token
    var refreshToken = auth.refreshToken();
    if (refreshToken) {
      return refreshTokensAndRepeat(error);
    } else {
      // we don't have proper credentials, let's obtain them!
      eventBus.$emit(SHOW_LOGIN_POPUP);
      return Promise.reject(error.response);
    }
  } else {
    // not 401 => no need to do anything here
    return Promise.reject(error.response);
  }
});

export default http;
