<template>
  <div>
    <component :is="component"></component>
  </div>
</template>

<script>
import { ADMINISTRATOR, MANAGER, DRIVER } from "@/const/user-role.js";

import AdministratorLinks from "@/components/navbar/roles-links/AdministratorLinks.vue";
import DriverLinks from "@/components/navbar/roles-links/DriverLinks.vue";
import ManagerLinks from "@/components/navbar/roles-links/ManagerLinks.vue";

export default {
  computed: {
    component() {
      switch (this.$store.state.userSettings.userInfo.role) {
        case ADMINISTRATOR:
          return AdministratorLinks;
        case MANAGER:
          return ManagerLinks;
        case DRIVER:
          return DriverLinks;
        default:
          return null;
      }
    }
  }
};
</script>
