import { ACCESS_TOKEN, REFRESH_TOKEN, USER_NAME, USER_ROLE } from "@/const/auth.js";

const userName = () => localStorage.getItem(USER_NAME);
const userRole = () => {
  var retVal = localStorage.getItem(USER_ROLE);
  if (retVal == "") retVal = null;

  return retVal;
};
const accessToken = () => localStorage.getItem(ACCESS_TOKEN);
const refreshToken = () => localStorage.getItem(REFRESH_TOKEN);
const isAuthorized = () => !!accessToken();

function clear() {
  localStorage.removeItem(USER_NAME);
  localStorage.removeItem(USER_ROLE);
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
}

function set({ accessToken, refreshToken, userName, role }) {
  localStorage.setItem(USER_NAME, userName);
  localStorage.setItem(USER_ROLE, role == null ? "" : role);
  localStorage.setItem(ACCESS_TOKEN, accessToken);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
}

function getTokens() {
  return { accessToken: accessToken(), refreshToken: refreshToken() };
}

export default {
  userName,
  userRole,
  accessToken,
  refreshToken,
  clear,
  set,
  isAuthorized,
  getTokens
};
