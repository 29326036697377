<template>
  <div>
    <b-dropdown-item v-b-modal.serial-number-item-searcher-modal>
      {{ $t("navbar-links.item-search") }}
    </b-dropdown-item>
    <b-modal
      id="serial-number-item-searcher-modal"
      @ok.prevent="searchSerialNumberItem"
      @show="resetSearchForm"
      size="lg"
      :title="$t('sn-item-searcher.modal.title')"
      :ok-title="$t('sn-item-searcher.modal.ok-btn-caption')"
      :cancel-title="$t('sn-item-searcher.modal.cancel-btn-caption')"
      :ok-disabled="!isSearchTermValid"
    >
      <template>
        <b-overlay :show="isSearching">
          <b-row>
            <b-col sm="3">
              <label for="input-valid">{{ $t("sn-item-searcher.modal.search-term-label") }}</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                :state="isSearchTermValid"
                :placeholder="$t('sn-item-searcher.modal.search-term-placeholder')"
                v-model.trim="searchTerm"
                autofocus
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row v-if="showSearchResult">
            <serial-number-item-search-result :searchResult="searchResult"></serial-number-item-search-result>
          </b-row>
        </b-overlay>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/services/api.js";
import SerialNumberItemSearchResult from "@/components/serial-number-item-history/SerialNumberItemSearchResult.vue";

export default {
  components: {
    SerialNumberItemSearchResult
  },
  data() {
    return {
      searchTerm: "",
      searchResult: [],
      isSearching: false,
      showSearchResult: false
    };
  },
  computed: {
    isSearchTermValid() {
      return this.searchTerm !== "";
    }
  },
  methods: {
    searchSerialNumberItem() {
      this.isSearching = true;
      api
        .searchSerialNumberItem(this.searchTerm)
        .then(resp => {
          switch (resp.data.length) {
            case 1: // only one item found -> go directly to history page
              if (
                !(
                  this.$router.currentRoute.name == "serial-number-item" &&
                  this.$router.currentRoute.params.serialNumberItemId &&
                  this.$router.currentRoute.params.serialNumberItemId == resp.data[0].id
                )
              ) {
                this.$router.push({
                  name: "serial-number-item",
                  params: { serialNumberItemId: resp.data[0].id }
                });
              }
              this.$bvModal.hide("serial-number-item-searcher-modal");
              break;
            default:
              // zero or multiple results - show them here
              this.searchResult = resp.data;
          }
        })
        .catch(() => alert(this.$t("sn-item-searcher.error.general")))
        .finally(() => {
          this.isSearching = false;
          this.showSearchResult = true;
        });
    },
    resetSearchForm() {
      this.searchTerm = "";
      this.searchResult = [];
      this.showSearchResult = false;
    }
  }
};
</script>
