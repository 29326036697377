import { ADMINISTRATOR, MANAGER, DRIVER } from "@/const/user-role.js";
import auth from "@/services/auth.js";
import redirectWithChecksMixin from "@/mixins/redirect-with-check.js";

export default {
  mixins: [redirectWithChecksMixin],
  methods: {
    redirectUserToHisPage() {
      if (auth.isAuthorized()) {
        const role = auth.userRole();
        switch (role) {
          case ADMINISTRATOR:
            this.redirectToRoute("administrator-home");
            break;
          case MANAGER:
            this.redirectToRoute("manager-home");
            break;
          case DRIVER:
            this.redirectToRoute("driver-home");
            break;
          case null:
            this.redirectToRoute("no-role-home");
            break;
        }
      } else {
        this.redirectToRoute("home");
      }
    }
  }
};
