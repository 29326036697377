import api from "@/services/api.js";

export default {
  namespaced: true,
  state: {
    limit: 20,
    events: []
  },
  mutations: {
    setEvents(state, payload) {
      state.events = payload;
    }
  },
  actions: {
    load({ state, commit }) {
      return api.events.find(state.limit).then(resp => {
        commit("setEvents", resp.data);

        return Promise.resolve();
      });
    }
  }
};
