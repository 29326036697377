import http from "@/http.js";

export const vehicle = {
  namespaced: true,
  actions: {
    getAll() {
      return http.get("api/vehicle/all");
    },
    add(context, { vm, allowSameVinIfSold }) {
      return http.post("api/vehicle/add", { vehicle: vm, allowSameVinIfSold: allowSameVinIfSold });
    },
    update(context, { vm, allowSameVinIfSold }) {
      return http.post("api/vehicle/update", { vehicle: vm, allowSameVinIfSold: allowSameVinIfSold });
    },
    delete(context, vm) {
      return http.post("api/vehicle/delete", vm);
    },
    getUserPermission(context, { vehicleId }) {
      return http.get(`api/vehicle/${vehicleId}/user-permission`);
    },
    getRegistrationNumber(context, vehicleId) {
      return http.get(`api/vehicle/${vehicleId}/registration-number`).then(resp => {
        return Promise.resolve(new String(resp.data));
      });
    },
    import(context, vm) {
      return http.post("api/vehicle/import", vm);
    }
  }
};
