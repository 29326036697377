import http from "@/http.js";
import api from "@/services/api.js";

export default {
  namespaced: true,
  state: {
    userInfo: {
      login: "",
      role: ""
    },
    userInfoIsLoading: false
  },
  mutations: {
    updateUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setLoadingState(state, loadingState) {
      state.userInfoIsLoading = loadingState;
    },
    setUserRole(state, role) {
      state.userInfo.role = role;
    }
  },
  actions: {
    getUserInfo(context) {
      context.commit("setLoadingState", true);
      return http
        .get("api/user/my-info")
        .then(resp => {
          context.commit("updateUserInfo", resp.data);
        })
        .finally(() => {
          context.commit("setLoadingState", false);
        });
    },
    changePassword(context, vm) {
      return http.post("api/user/change-password", vm);
    },
    updatePanelSetting(context, panelSetting) {
      return api.updatePanelSetting(panelSetting);
    }
  }
};
