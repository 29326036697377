export const LOGIN = "login";
export const LOGOUT = "logout";
export const UPDATE_GOODS_GROUP = "update-goods-group";
export const OPEN_EDIT_GOODS_GROUP_MODAL = "open-edit-goods-group-modal";
export const OPEN_TEMPLATE_MODAL = "open-template-modal";
export const UPDATE_TEMPLATE_LIST = "update-template-list";
export const UPDATE_PRODUCT = "update-product";
export const OPEN_EDIT_PRODUCT_MODAL = "open-edit-product-modal";
export const OPEN_CREATE_PRODUCT_MODAL = "open-create-product-modal";
export const SHOW_LOGIN_POPUP = "show-login-popup";
export const UPDATE_INCIDENT_LIST = "update-incident-list";
export const OPEN_EDIT_INCIDENT_MODAL = "open-edit-incident-modal";
export const OPEN_NEW_INCIDENT_MODAL = "open-new-incident-modal";
export const UPDATE_VEHICLE_LOAD_LIST = "update-vehicle-load-list";
export const UPDATE_USERS = "update-users";
export const OPEN_PURITY_MODAL = "open-purity-modal";
export const VEHICLE_ACCEPTANCE_OR_CHECKOUT = "vehicle-acceptance-or-checkout";
export const RELOAD_TRANSACTION_LIST = "reload-transaction-list";
export const VEHICLE_ADDED = "vehicle-added";
export const VEHICLE_EDITED = "vehicle-edited";
export const VEHICLE_DELETED = "vehicle-deleted";
export const VEHICLES_IMPORTED = "vehicles-imported";
export const OPEN_IMAGE_MODAL = "open-image-modal";
export const SN_ITEM_ADDED = "sn-item-added";
