import api from "@/services/api.js";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    initialLoadItemsSerialized: "",
    loadItems: [],
    vehicleId: 0,
    goodsSerialNumbers: []
  },
  getters: {
    isValid: state => {
      return state.loadItems.every(
        x => x.balance >= 0 && (x.goodsInfo.hasSerialNumber ? x.serialNumbers.length === x.balance : true)
      );
    },
    itemsForExport: state => {
      return state.loadItems.map(x => {
        return {
          goodsId: x.goodsInfo.id,
          count: x.balance,
          serialNumbers: x.serialNumbers
        };
      });
    },
    goodsInfoWithSerialNumbersIds: state => {
      return state.loadItems.filter(x => x.goodsInfo.hasSerialNumber).map(x => x.goodsInfo.id);
    }
  },
  mutations: {
    setLoadingState(state, isLoading) {
      state.isLoading = isLoading;
    },
    setInitialData(state, { currentLoad, template, vehicleId }) {
      let templateItems = template.goodsTemplates || [];
      // combine load items and template into one array
      state.loadItems = currentLoad.map(x => {
        let result = {
          goodsInfo: x.goodsInfo,
          count: x.count,
          serialNumbers: x.serialNumbers,
          expended: 0,
          balance: x.count
        };
        let templateInfo = templateItems.find(y => y.goods.id == x.goodsInfo.id);
        if (templateInfo) {
          result.minQuantity = templateInfo.minQuantity;
          result.quantity = templateInfo.quantity;
        } else {
          result.minQuantity = 0;
          result.quantity = 0;
        }
        return result;
      });
      state.initialLoadItemsSerialized = JSON.stringify(state.loadItems);
      state.vehicleId = vehicleId;
    },
    addSerialNumbers(state, goodsSerialNumbers) {
      state.goodsSerialNumbers.push(goodsSerialNumbers);
    },
    reset(state) {
      state.loadItems = JSON.parse(state.initialLoadItemsSerialized);
    },
    clear(state) {
      state.isLoading = false;
      state.initialLoadItemsSerialized = "";
      state.loadItems = [];
      state.vehicleId = 0;
      state.goodsSerialNumbers = [];
    },
    setNewSerialNumbers(state, { id, newSerialNumbers }) {
      state.loadItems.forEach((x, i) => {
        if (x.goodsInfo.id == id) {
          x.serialNumbers = newSerialNumbers;
          x.balance = newSerialNumbers.length;
          x.expended = x.count - x.balance;
          Vue.set(state.loadItems, i, x);
        }
      });
    },
    setExpended(state, { id, newExpended }) {
      state.loadItems.forEach((x, i) => {
        if (x.goodsInfo.id == id) {
          x.expended = newExpended;
          x.balance = x.count + x.expended;
          Vue.set(state.loadItems, i, x);
        }
      });
    },
    setBalance(state, { id, newBalance }) {
      state.loadItems.forEach((x, i) => {
        if (x.goodsInfo.id == id) {
          x.balance = newBalance;
          x.expended = x.count - x.balance;
          Vue.set(state.loadItems, i, x);
        }
      });
    }
  },
  actions: {
    loadData({ commit, getters }, vehicleId) {
      commit("clear");
      let currentLoad = api.getVehicleCurrentLoadItems(vehicleId);
      let template = api.getVehicleTemplate(vehicleId);
      commit("setLoadingState", true);
      return Promise.all([currentLoad, template])
        .then(resp => {
          commit("setInitialData", { currentLoad: resp[0].data, template: resp[1].data, vehicleId });

          let availableSerialNumberRequests = [];
          getters.goodsInfoWithSerialNumbersIds.forEach(id => {
            availableSerialNumberRequests.push(
              api.getAvailableSerialNumbers(id).then(resp => {
                commit("addSerialNumbers", resp.data);
              })
            );
          });
          if (availableSerialNumberRequests.length > 0) {
            Promise.all(availableSerialNumberRequests);
          }
        })
        .finally(() => commit("setLoadingState", false));
    }
  }
};
