import api from "@/services/api.js";

export default {
  namespaced: true,
  state: {
    transactions: [],
    isLoading: false,
    totalRowCount: 0,
    searchParams: {},
    selectedTransactions: [],
    sortBy: null,
    sortDesc: false,
    filter: null
  },
  getters: {
    totalRowCount: state => state.totalRowCount,
    searchParams: state => state.searchParams,
    transactions: state => state.transactions,
    selectedTransactions: state => state.selectedTransactions,
    isSelectedTransaction: state => id => state.selectedTransactions.includes(id)
  },
  mutations: {
    setTotalRowCount(state, count) {
      state.totalRowCount = count;
    },
    setSearchParams(state, params) {
      state.searchParams = params;
    },
    setTransactions(state, { transactions }) {
      state.transactions = transactions;
    },
    setLoadingState(state, isLoading) {
      state.isLoading = isLoading;
    },
    setSortBy(state, sortBy) {
      state.sortBy = sortBy;
    },
    setSortDesc(state, sortDesc) {
      state.sortDesc = sortDesc;
    },
    setTransactionsFilter(state, filter) {
      state.filter = filter;
    },
    setSelectedState(state, { transactionIds, isSelected }) {
      if (isSelected === true) {
        state.selectedTransactions = [...state.selectedTransactions, ...transactionIds];
      } else {
        state.selectedTransactions = state.selectedTransactions.filter(x => !transactionIds.includes(x));
      }
    },
    clear(state) {
      state.transactions = [];
      state.isLoading = false;
      state.totalRowCount = 0;
      state.selectedTransactions = [];
      state.sortBy = "";
      state.sortDesc = false;
    }
  },
  actions: {
    loadTransactions({ getters, commit, dispatch, rootGetters, state }, { params, filter, page, locale }) {
      commit("setTransactionsFilter", filter);

      if (params) commit("setSearchParams", params);
      else params = getters.searchParams;

      return dispatch("settings/appearance/initState", null, { root: true }).then(() => {
        commit("setLoadingState", true);
        if (!page) page = 1;
        let vm = {
          params,
          filter,
          sortBy: state.sortBy,
          sortDesc: state.sortDesc,
          locale,
          currentPage: page,
          itemsPerPage: rootGetters["settings/appearance/commonTableRowCount"]
        };
        api
          .searchTransactionsWithPages(vm)
          .then(resp => {
            commit("setTotalRowCount", resp.data.totalRowCount);
            commit("setTransactions", { transactions: resp.data.transactions || [] });
          })
          .finally(() => commit("setLoadingState", false));
      });
    },
    exportTransactions({ state }, { locale }) {
      return api.exportTransactions({
        params: state.searchParams,
        filter: state.filter,
        sortBy: state.sortBy,
        sortDesc: state.sortDesc,
        locale
      });
    }
  }
};
