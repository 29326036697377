import http from "@/http.js";

export const vehicleType = {
  namespaced: true,
  actions: {
    getAll() {
      return http.get("api/vehicle-type/all");
    },
    add(context, vm) {
      return http.post("api/vehicle-type/add", vm);
    },
    update(context, vm) {
      return http.post("api/vehicle-type/update", vm);
    },
    remove(context, vm) {
      return http.post("api/vehicle-type/remove", vm);
    }
  }
};
