import api from "@/services/api.js";

export const i18n = {
  namespaced: true,
  state: {
    strings: [],
    editableIdList: new Set()
  },
  getters: {
    strings: state => state.strings,
    getStringValue: state => (id, locale) => {
      let item = state.strings.find(x => x.id === id);
      return item != null ? item[locale] : null;
    }
  },
  mutations: {
    setStrings(state, payload) {
      state.strings = payload;
    },
    updateString(state, { id, locale, value }) {
      let item = state.strings.find(x => x.id === id);
      if (item != null) {
        state.editableIdList.add(id);
        item[locale] = value;
      }
    },
    reset(state) {
      state.strings = [];
      state.editableIdList.clear();
    }
  },
  actions: {
    loadUserStrings({ commit }) {
      return api.getUserStrings().then(resp => commit("setStrings", resp.data));
    },
    loadUIStrings({ commit }) {
      return api.getUIStrings().then(resp => {
        let prefix = "";
        let strings = mergeLocales({
          en: flatten(resp[0].data, prefix),
          ru: flatten(resp[1].data, prefix),
          lt: flatten(resp[2].data, prefix)
        });
        commit("setStrings", strings);
      });
    },
    updateUserStrings({ state }) {
      let vm = state.strings.filter(x => state.editableIdList.has(x.id));
      state.editableIdList.clear();
      return api.updateUserStrings(vm);
    },
    updateUIStrings({ state }) {
      let vm = state.strings.filter(x => state.editableIdList.has(x.id));
      state.editableIdList.clear();
      return api.updateUIStrings(vm);
    }
  }
};

function flatten(obj, prefix) {
  let result = [];

  if (!prefix) prefix = "";

  Object.keys(obj).forEach(key => {
    let id = prefix === "" ? key : `${prefix}.${key}`;
    if (typeof obj[key] === "object" && obj[key] !== null) {
      result = result.concat(flatten(obj[key], id).flat());
    } else {
      result.push({ id: id, value: obj[key] });
    }
  });
  return result;
}

function mergeLocales({ en, ru, lt }) {
  return en.map(x => {
    let ruItem = ru.find(y => y.id === x.id);
    let ltItem = lt.find(y => y.id === x.id);

    return {
      id: x.id,
      en: x.value,
      ru: ruItem ? ruItem.value : "",
      lt: ltItem ? ltItem.value : ""
    };
  });
}
