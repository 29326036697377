<template>
  <b-modal ref="login-modal" v-bind:title="$t('login-modal.authorization')" @hide="onHideModal" hide-footer>
    <template>
      <b-row class="mx-2 mb-2">
        <b-col>
          <b-input
            type="text"
            v-model="login"
            class="form-control"
            v-bind:placeholder="$t('login-modal.login')"
            @keyup.enter="submit"
          />
        </b-col>
      </b-row>
      <b-row class="mx-2 mb-3">
        <b-col>
          <b-form-input
            type="password"
            v-model="password"
            v-bind:placeholder="$t('login-modal.password')"
            @keyup.enter="submit"
          />
        </b-col>
      </b-row>
      <b-row class="mx-2">
        <b-col>
          <button type="button" class="btn btn-primary float-right" @keyup.enter="submit" v-on:click="submit">
            {{ $t("login-modal.ok") }}
          </button>
          <button type="button" class="btn btn-secondary float-right mr-2" data-dismiss="modal" @click="hide">
            {{ $t("login-modal.cancel") }}
          </button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import auth from "@/services/auth.js";
import { LOGIN } from "@/const/events.js";
import http from "@/http.js";
import redirectUserToHisPageMixin from "@/mixins/redirect-user-to-his-page.js";

export default {
  mixins: [redirectUserToHisPageMixin],
  data() {
    return this.getDefaultData();
  },
  methods: {
    show() {
      this.$refs["login-modal"].show();
    },
    hide() {
      this.$refs["login-modal"].hide();
      // if user is unauthorized => return to home
      if (!auth.isAuthorized()) {
        this.redirectToRoute("home");
      }
    },
    checkUserCredentials(userCredentials) {
      return new Promise((resolve, reject) => {
        http
          .post("/api/user/login", userCredentials)
          .then(resp => {
            resolve(resp.data);
          })
          .catch(err => {
            if (err.data.errorCode) {
              reject({ ErrorMessage: this.$t("auth." + err.data.errorCode) });
            } else {
              reject({ ErrorMessage: this.$t("auth.general-error-message") });
            }
          });
      });
    },
    submit() {
      if (this.login === "" || this.password === "") {
        alert(this.$t("auth.login-or-password-is-empty"));
        return;
      }

      this.checkUserCredentials({
        login: this.login,
        password: this.password
      })
        .then(data => {
          if (data.shouldChangePassword) {
            this.redirectToRoute("setting-new-password", { changePasswordToken: data.changePasswordToken });
            this.$refs["login-modal"].hide();
          } else {
            auth.set(data);
            this.$bus.emit(LOGIN);
            this.hide();
            this.redirectUserToHisPage();
          }
        })
        .catch(data => {
          alert(data.ErrorMessage);
        });
    },
    onHideModal() {
      Object.assign(this.$data, this.getDefaultData());
    },
    getDefaultData() {
      return {
        login: "",
        password: ""
      };
    }
  }
};
</script>
