import http from "@/http.js";

function saveLocale(locale) {
  localStorage.setItem("ollex-locale", locale);
}

export function getCurrentLocale() {
  return localStorage.getItem("ollex-locale");
}

export function setLocale(i18n, locale) {
  if (i18n.availableLocales.includes(locale)) {
    i18n.locale = locale;
    saveLocale(locale);
  } else {
    getLocale(locale).then(x => {
      i18n.setLocaleMessage(locale, x.data);
      i18n.locale = locale;
      saveLocale(locale);
    });
  }
}

export function updateLocaleMessages(i18n, locale) {
  getLocale(locale).then(x => {
    i18n.setLocaleMessage(locale, x.data);
  });
}

function getLocale(locale) {
  return http.get(`api/i18n/ui-string/${locale}`);
}
