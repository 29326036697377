import api from "@/services/api.js";

export const dbSettings = {
  namespaced: true,
  state: {
    connectionString: {
      server: "",
      database: "",
      user: "",
      password: ""
    }
  },
  getters: {
    server: state => state.connectionString.server,
    database: state => state.connectionString.database,
    user: state => state.connectionString.user,
    password: state => state.connectionString.password
  },
  mutations: {
    setConnectionString(state, payload) {
      state.connectionString = payload;
    },
    setServer(state, payload) {
      state.connectionString.server = payload;
    },
    setDatabase(state, payload) {
      state.connectionString.database = payload;
    },
    setUser(state, payload) {
      state.connectionString.user = payload;
    },
    setPassword(state, payload) {
      state.connectionString.password = payload;
    }
  },
  actions: {
    getInitState() {
      return api.getConnectionString();
    },
    applyConnectionString({ state }) {
      return api.setConnectionString(state.connectionString);
    }
  }
};
