import Vue from "vue";
import VueI18n from "vue-i18n";

import { getCurrentLocale, setLocale } from "@/services/locale.js";

import { EN, RU, LT } from "@/const/langs.js";

Vue.use(VueI18n);

let locale = getCurrentLocale() || EN;

const dateTileBaseFormat = {
  short: { year: "numeric", month: "short", day: "numeric" },
  long: {
    year: "numeric",
    month: "short",
    day: "numeric",
    weekday: "short",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false
  },
  log: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false
  }
};

export const i18n = new VueI18n({
  locale,
  dateTimeFormats: {
    [EN]: dateTileBaseFormat,
    [RU]: dateTileBaseFormat,
    [LT]: dateTileBaseFormat
  },
  silentTranslationWarn: true
});

setLocale(i18n, locale);
