import BootstrapVue from "bootstrap-vue";
import Vue from "vue";
import VueBus from "vue-bus";
import vSelect from "vue-select";
import Icon from "vue-awesome/components/Icon";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "./i18n";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.min.css";
import "vue-select/dist/vue-select.css";

import "vue-awesome/icons";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueBus);
Vue.component("v-select", vSelect);
Vue.component("v-icon", Icon);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
