import http from "@/http.js";

export const vehicleCategory = {
  namespaced: true,
  actions: {
    getAll() {
      return http.get("api/vehicle-category/all");
    },
    add(context, vm) {
      return http.post("api/vehicle-category/add", vm);
    },
    update(context, vm) {
      return http.post("api/vehicle-category/update", vm);
    },
    remove(context, vm) {
      return http.post("api/vehicle-category/remove", vm);
    }
  }
};
