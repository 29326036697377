import http from "@/http.js";
import api from "@/services/api.js";

export const incidents = {
  namespaced: true,
  state: {
    incidents: []
  },
  getters: {
    incidents: state => state.incidents,
    incident: state => id => state.incidents.find(x => x.id === id)
  },
  mutations: {
    setIncidentsState(state, incidents) {
      state.incidents = incidents;
    }
  },
  actions: {
    get(context, id) {
      return http.get(`api/incidents/${id}`);
    },
    getOpenIncidents(context, params) {
      return http.post("api/incidents/open/all", params);
    },
    getClosedIncidents(context, params) {
      return http.post("api/incidents/closed/all", params);
    },
    downloadFile(context, vm) {
      return http.post("api/incidents/file", vm, { responseType: "blob" });
    },
    add(context, vm) {
      return http.post("api/incidents/add", vm);
    },
    update(context, vm) {
      return http.post("api/incidents/update", vm);
    },
    remove(context, id) {
      return http.delete(`api/incidents/${id}`);
    },
    export(context, locale) {
      return api.incidents.export(locale);
    }
  }
};
