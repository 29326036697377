<template>
  <b-navbar-nav>
    <b-nav-item-dropdown :text="$t('navbar-links.transport')">
      <b-dropdown-item :to="{ name: 'vehicle-load' }">{{ $t("navbar-links.loading-unloading") }}</b-dropdown-item>
      <b-dropdown-item :to="{ name: 'goods-loading' }">{{ $t("navbar-links.items") }}</b-dropdown-item>
      <b-dropdown-item :to="{ name: 'vehicle-management' }">{{ $t("navbar-links.current-state") }}</b-dropdown-item>
      <b-dropdown-item :to="{ name: 'current-loads' }">{{ $t("navbar-links.current-load") }}</b-dropdown-item>
      <b-dropdown-item :to="{ name: 'templates' }">{{ $t("navbar-links.changing-templates") }}</b-dropdown-item>
    </b-nav-item-dropdown>
    <b-nav-item-dropdown :text="$t('navbar-links.sales')">
      <b-dropdown-item :to="{ name: 'driver-transactions' }">{{ $t("navbar-links.by-driver") }}</b-dropdown-item>
      <b-dropdown-item :to="{ name: 'sold-items-transactions' }">{{ $t("navbar-links.by-subject") }}</b-dropdown-item>
      <b-dropdown-item :to="{ name: 'transactions' }">{{ $t("navbar-links.transactions") }}</b-dropdown-item>
    </b-nav-item-dropdown>
    <b-nav-item-dropdown :text="$t('navbar-links.reports')">
      <serial-number-item-searcher></serial-number-item-searcher>
      <b-dropdown-item :to="{ name: 'vehicle-events' }">{{ $t("navbar-links.vehicle-history") }}</b-dropdown-item>
      <b-dropdown-item :to="{ name: 'inventory' }">{{ $t("navbar-links.inventory") }}</b-dropdown-item>
    </b-nav-item-dropdown>
    <b-nav-item :to="{ name: 'incident-open-list' }">{{ $t("navbar-links.incidents") }}</b-nav-item>
  </b-navbar-nav>
</template>

<script>
import SerialNumberItemSearcher from "@/components/serial-number-item-history/SerialNumberItemSearcher.vue";

export default {
  components: {
    SerialNumberItemSearcher
  }
};
</script>
