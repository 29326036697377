import api from "@/services/api.js";

export default {
  namespaced: true,
  state: {
    items: [],
    limit: 20
  },
  mutations: {
    clear(state) {
      state.items = [];
    },
    set(state, payload) {
      state.items = payload;
    }
  },
  actions: {
    load({ state, commit }, vehicleId) {
      commit("clear");
      return api.incidents.find({ vehicleId, limit: state.limit }).then(resp => commit("set", resp.data));
    }
  }
};
