export function compareRows(value1, value2) {
  return value1.toUpperCase().includes(value2.toUpperCase());
}

// ci == case-insensitive
function ciIncludes(value, filter) {
  if (!filter) return true; // if no filter - check passed
  if (!value) return false; // filter exists, but value is empty - check not passed
  return value.toUpperCase().includes(filter.toUpperCase());
}

export default {
  compareRows,
  ciIncludes
};
