import http from "@/http.js";

export const users = {
  namespaced: true,
  actions: {
    getAll() {
      return http.get("api/user/getAll");
    },
    add(context, vm) {
      return http.post("api/user/add", vm);
    },
    update(context, vm) {
      return http.post("api/user/update", vm);
    },
    remove(context, id) {
      return http.post(`api/user/remove/${id}`);
    },
    "reset-password"(context, id) {
      return http.post(`api/user/reset-password/${id}`);
    },
    import(context, file) {
      return http.post("api/user/import", file);
    }
  }
};
