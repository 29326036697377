import api from "@/services/api.js";
import { i18n } from "@/i18n";

export default {
  namespaced: true,
  state: {
    vehicles: [],
    isLoading: false,
    selectedGoodsIds: [],
    selectedGoodsGroupsIds: [],
    filter: {
      showLessThanMinimal: false,
      showLessThanRecommended: false,
      showMoreThanRecommended: false,
      registrationNumber: "",
      brand: "",
      model: "",
      category: "",
      type: "",
      template: "",
      loadedBy: "",
      loadedAt: ""
    },
    pagination: {
      limit: 20,
      currentPage: 1,
      totalCount: 0
    },
    sort: {
      by: "",
      isDesc: false
    }
  },
  mutations: {
    setSelectedGoodsIds(state, goodsIds) {
      state.selectedGoodsIds = goodsIds;
    },
    setSelectedGoodsGroupsIds(state, goodsGroupsIds) {
      state.selectedGoodsGroupsIds = goodsGroupsIds;
    },
    setLoadingState(state, isLoading) {
      state.isLoading = isLoading;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    setSearchResult(state, { items, totalCount }) {
      state.vehicles = items;
      state.pagination.totalCount = totalCount;
    },
    setSortParams(state, { sortBy, isSortDesc }) {
      state.sort.by = sortBy;
      state.sort.isDesc = isSortDesc;
    },
    setCurrentPage(state, payload) {
      state.pagination.currentPage = payload;
    }
  },
  actions: {
    setFilter({ commit, dispatch }, filter) {
      commit("setFilter", filter);
      return dispatch("loadData");
    },
    setSelectedGoods({ commit, dispatch }, selectedGoodsIds) {
      commit("setSelectedGoodsIds", selectedGoodsIds);
      return dispatch("loadData");
    },
    setSortParams({ commit, dispatch }, { sortBy, isSortDesc }) {
      commit("setCurrentPage", 1);
      commit("setSortParams", { sortBy, isSortDesc });
      return dispatch("loadData");
    },
    setCurrentPage({ commit, dispatch }, page) {
      commit("setCurrentPage", page);
      return dispatch("loadData");
    },
    loadData({ commit, state }) {
      commit("setLoadingState", true);
      let searchParams = { ...state.filter };
      searchParams.goodsIds = state.selectedGoodsIds;
      searchParams.locale = i18n.locale;
      searchParams.limit = state.pagination.limit;
      searchParams.offset = state.pagination.limit * (state.pagination.currentPage - 1);
      searchParams.limit = state.pagination.limit;
      searchParams.sortBy = state.sort.by;
      searchParams.isSortDesc = state.sort.isDesc;
      return api
        .getVehiclesWithIncompleteLoads(searchParams)
        .then(resp => commit("setSearchResult", resp.data))
        .finally(() => commit("setLoadingState", false));
    }
  }
};
