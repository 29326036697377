import http from "@/http.js";

export const dictionary = {
  namespaced: true,
  actions: {
    getTemplates() {
      return http.get("api/dictionary/templates");
    }
  }
};
