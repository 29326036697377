<template>
  <div id="app" class="wrap-container">
    <nav-bar id="navigation-bar"></nav-bar>
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from "@/components/navbar/Navbar.vue";

export default {
  components: {
    "nav-bar": Navbar
  }
};
</script>

<style scoped>
.wrap-container {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
</style>
