import http from "@/http.js";

export const purityCheck = {
  namespaced: true,
  actions: {
    getActualCheck() {
      return http.get("api/purity-check/actual");
    },
    setCheckList(context, vm) {
      return http.post("api/purity-check/set", vm);
    }
  }
};
