import api from "@/services/api.js";

export default {
  namespaced: true,
  state: {
    filter: { role: null, user: null, event: null },
    searchParams: { from: null, to: null, vehicleId: NaN },
    selectedVehicle: {},
    events: [],
    users: []
  },
  getters: {
    events: state =>
      state.events.filter(
        x =>
          (state.filter.role == null || x.createdBy.role === state.filter.role) &&
          (state.filter.user == null || x.createdBy.userName === state.filter.user) &&
          (state.filter.event == null || x.type === state.filter.event)
      ),
    vehicle: state => state.selectedVehicle,
    filter: state => state.filter,
    users: state => {
      if (state.filter.role == null) return state.users;
      return state.users.filter(x => x.role === state.filter.role);
    }
  },
  mutations: {
    setSearchParams(state, params) {
      state.searchParams = params;
    },
    setEventsState(state, events) {
      state.events = events;
      state.events.sort(function(a, b) {
        if (a.createdAt > b.createdAt) return -1;
        if (a.createdAt < b.createdAt) return 1;
        return 0;
      });
    },
    setSelectedVehicle(state, vehicle) {
      state.selectedVehicle = vehicle;
    },
    setFilterState(state, { field, value }) {
      if (field === "role") state.filter.user = null;
      state.filter[field] = value;
    },
    setUsersState(state, users) {
      state.users = users;
    },
    clear(state) {
      state.searchParams = { from: null, to: null, vehicleId: NaN };
      state.filter = { role: null, user: null, event: null };
      state.events = [];
    }
  },
  actions: {
    loadEvents({ state, commit }) {
      api.searchVehicleEvents(state.searchParams).then(resp => commit("setEventsState", resp.data));
    },
    loadUsers() {
      return api.getAllUsers();
    }
  }
};
