import Vue from "vue";

import api from "@/services/api.js";
import { i18n } from "@/i18n";

let lastDayOfPreviousMonth = new Date();
lastDayOfPreviousMonth.setDate(1);
lastDayOfPreviousMonth.setHours(-1);

export default {
  namespaced: true,
  state: {
    transactions: [],
    isLoading: false,
    filters: {
      createdTo: lastDayOfPreviousMonth.toISOString().slice(0, 10),
      driver: null,
      goods: "",
      createdAt: null,
      count: ""
    },
    pagination: {
      limit: 20,
      offset: 0,
      currentPage: 1,
      totalCount: 0
    },
    sort: {
      by: "",
      isDesc: false
    }
  },
  getters: {
    filters: state => state.filters,
    pagination: state => state.pagination
  },
  mutations: {
    setTransactions(state, transactions) {
      state.transactions = transactions;
    },
    setLoadingState(state, isLoading) {
      state.isLoading = isLoading;
    },
    setSelectedState(state, { transactionIds, isSelected }) {
      state.transactions.forEach((x, i) => {
        if (transactionIds.includes(x.id)) {
          x.selected = isSelected;
          Vue.set(state.transactions, i, x);
        }
      });
    },
    clear(state) {
      state.transactions = [];
      state.isLoading = false;
    },
    setSort(state, { sortBy, sortDesc }) {
      state.sort.by = sortBy;
      state.sort.isDesc = sortDesc;
    },
    setCurrentPage(state, payload) {
      state.pagination.currentPage = payload;
    }
  },
  actions: {
    loadTransactions({ state, commit }) {
      if (!state.filters.driver) return Promise.reject();

      commit("setLoadingState", true);
      const searchParams = {
        ...state.filters,
        createdAt: state.filters.createdAt ? state.filters.createdAt : null,
        offset: state.pagination.limit * (state.pagination.currentPage - 1),
        limit: state.pagination.limit,
        sortBy: state.sort.by,
        isSortDesc: state.sort.isDesc,
        locale: i18n.locale
      };
      return api
        .findTransactionsByDriver(state.filters.driver.userId, searchParams)
        .then(resp => {
          commit("setTransactions", resp.data.items || []);
          state.pagination.totalCount = resp.data.totalCount;
        })
        .finally(() => commit("setLoadingState", false));
    },
    setFilter({ state, commit, dispatch }, { property, value }) {
      if (state.filters.hasOwnProperty(property)) {
        commit("setCurrentPage", 1);

        state.filters[property] = value;

        return dispatch("loadTransactions");
      }

      return Promise.reject();
    },
    setCurrentPage({ state, dispatch }, page) {
      state.pagination.currentPage = page;

      return dispatch("loadTransactions");
    },
    setSort({ commit, dispatch }, ctx) {
      commit("setSort", ctx);
      commit("setCurrentPage", 1);
      return dispatch("loadTransactions");
    }
  }
};
