import api from "@/services/api.js";

export default {
  namespaced: true,
  state: {
    goods: []
  },
  getters: {
    goods: state => state.goods
  },
  mutations: {
    clear(state) {
      state.goods = [];
    },
    set(state, payload) {
      state.goods = payload;
    }
  },
  actions: {
    load({ commit }, vehicleId) {
      commit("clear");

      return api.getLoadedGoods(vehicleId).then(resp => {
        commit("set", resp.data);
      });
    }
  }
};
