<template>
  <b-table
    :items="filteredItems"
    :fields="fields"
    caption-top
    show-empty
    :empty-text="$t('sn-item-searcher.result.empty')"
  >
    <template v-slot:top-row>
      <td>
        <b-input v-model.trim="filter.title" :placeholder="$t('sn-item-searcher.result.filters.title')"></b-input>
      </td>
      <td>
        <b-input v-model.trim="filter.code" :placeholder="$t('sn-item-searcher.result.filters.code')"></b-input>
      </td>
      <td>
        <b-input
          v-model.trim="filter.serialNumber"
          :placeholder="$t('sn-item-searcher.result.filters.serial-number')"
        ></b-input>
      </td>
    </template>
    <template v-slot:cell(title)="row">
      <b-link @click="onLoadItemClick(row.item.id)">{{ row.value[$i18n.locale] }}</b-link>
    </template>
    <template v-slot:table-caption>
      <span v-if="searchResult.length > 0">
        {{ $t("sn-item-searcher.result.table-caption", { count: searchResult.length }) }}
      </span>
    </template>
  </b-table>
</template>

<script>
import comparator from "@/services/string-comparator.js";

export default {
  props: {
    searchResult: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      fields: [
        { key: "title", label: this.$t("sn-item-searcher.result.columns.title"), sortable: true },
        { key: "code", label: this.$t("sn-item-searcher.result.columns.code"), sortable: true },
        { key: "serialNumber", label: this.$t("sn-item-searcher.result.columns.serial-number"), sortable: true }
      ],
      filter: {
        title: "",
        code: "",
        serialNumber: ""
      }
    };
  },
  computed: {
    filteredItems() {
      return this.searchResult.filter(
        x =>
          comparator.compareRows(x.title[this.$i18n.locale], this.filter.title) &&
          comparator.compareRows(x.code, this.filter.code) &&
          comparator.compareRows(x.serialNumber, this.filter.serialNumber)
      );
    }
  },
  methods: {
    onLoadItemClick(serialNumberItemId) {
      const thisRoute =
        this.$router.currentRoute.name == "serial-number-item" &&
        this.$router.currentRoute.params.serialNumberItemId &&
        this.$router.currentRoute.params.serialNumberItemId == serialNumberItemId;

      if (!thisRoute) {
        this.$router.push({
          name: "serial-number-item",
          params: { serialNumberItemId: serialNumberItemId }
        });
      }
      // dirty, but we know we are here
      this.$bvModal.hide("serial-number-item-searcher-modal");
    }
  }
};
</script>
