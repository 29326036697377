<template>
  <div>
    <b-overlay :show="isLoading" no-wrap fixed z-index="1100"></b-overlay>
    <b-modal
      id="new-incident-modal"
      :title="$t('new-incident-modal.title')"
      :ok-title="$t('new-incident-modal.ok-title')"
      :cancel-title="$t('new-incident-modal.cancel-title')"
      @hidden="reset"
      @ok="onOk"
    >
      <template v-slot:default>
        <b-form-group :label="$t('new-incident-modal.type')">
          <v-select
            :clearable="false"
            :options="incidentTypeOptions"
            :getOptionLabel="x => x.title"
            @input="
              x => {
                incident.type = x.type;
                incident.categoryId = x.categoryId || null;
              }
            "
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-form-group>
        <b-form-group :label="$t('new-incident-modal.vehicle')">
          <v-select v-model="vehicle.id" :clearable="false" :options="vehicleOptions" :reduce="x => x.id">
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-form-group>
        <b-form-group :label="$t('new-incident-modal.responsible-user')" v-if="!isDriver">
          <v-select
            v-model="incident.responsibleUser"
            :clearable="false"
            :options="drivers"
            :reduce="x => x.id"
            label="userName"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-form-group>
        <b-form-group :label="$t('new-incident-modal.description')">
          <b-form-textarea v-model="incident.description" />
        </b-form-group>
        <b-form-group :label="$t('new-incident-modal.incident-responsible-user')" v-if="!isDriver">
          <v-select
            v-model="incident.incidentResponsibleUserId"
            :options="incidentResponsibleUsers"
            :reduce="x => x.id"
            label="userName"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox v-model="incident.isUrgent">
            {{ $t("new-incident-modal.is-urgent") }}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group :label="$t('new-incident-modal.files')">
          <input
            multiple
            type="file"
            ref="fileInput"
            class="d-none"
            accept="image/*,video/*"
            @change="onChangeUploadFiles"
          />
          <b-button @click="$refs.fileInput.click()">{{ $t("new-incident-modal.add-file-btn") }}</b-button>
          <ul class="mt-2">
            <li v-for="(x, i) in incident.files" :key="i">
              {{ x.name }}
            </li>
          </ul>
        </b-form-group>
      </template>
    </b-modal>
  </div>
</template>

<script>
import auth from "@/services/auth.js";
import { UPDATE_INCIDENT_LIST } from "@/const/events.js";
import {
  SALES_INCIDENT_TYPE,
  REPAIR_INCIDENT_TYPE,
  TRIM_LEVEL_INCIDENT_TYPE,
  CUSTOM_INCIDENT_TYPE
} from "@/const/incident-types.js";
import { DRIVER } from "@/const/user-role.js";
import { mapActions } from "vuex";

export default {
  props: {
    drivers: { type: Array, default: () => [] },
    incidentResponsibleUsers: { type: Array, default: () => [] },
    vehicleOptions: { type: Array, default: () => [] },
    vehicle: { type: Object }
  },
  data() {
    return {
      maxFileGroupSize: 20000000,
      incident: {
        type: "",
        categoryId: null,
        description: "",
        files: [],
        responsibleUser: NaN,
        incidentResponsibleUserId: null,
        isUrgent: false
      },
      isLoading: false
    };
  },
  computed: {
    incidentTypeOptions() {
      let result = [
        { type: SALES_INCIDENT_TYPE, title: this.$t("incident-type.sales") },
        { type: REPAIR_INCIDENT_TYPE, title: this.$t("incident-type.repair") },
        { type: TRIM_LEVEL_INCIDENT_TYPE, title: this.$t("incident-type.trim-level") }
      ];
      for (const category of this.issueCategories) {
        result.push({ title: category.title[this.$i18n.locale], type: CUSTOM_INCIDENT_TYPE, categoryId: category.id });
      }
      return result;
    },
    issueCategories() {
      return this.$store.state.issueCategories.items;
    },
    validForm() {
      // TODO quick fix, check later
      return Boolean(this.incident.type) && !!this.vehicle.id && Boolean(this.incident.description);
    },
    isDriver() {
      return auth.userRole() === DRIVER;
    }
  },
  methods: {
    ...mapActions(["incidents/add"]),
    reset() {
      this.vehicle.id = NaN;
      this.incident = {
        type: SALES_INCIDENT_TYPE,
        categoryId: null,
        description: "",
        files: [],
        responsibleUser: NaN,
        incidentResponsibleUserId: null
      };
    },
    onChangeUploadFiles(e) {
      let files = e.target.files;
      let totalFileSize = 0;

      Array.from(files).forEach(x => (totalFileSize += x.size));

      if (totalFileSize > this.maxFileGroupSize) {
        alert(this.$t("new-incident-modal.file-too-big"));
      } else {
        this.incident.files = files;
      }
    },
    onOk(e) {
      e.preventDefault();

      if (this.validForm === false) {
        alert(this.$t("new-incident-modal.wrong-form"));
        return;
      }

      this.isLoading = true;
      let form = new FormData();

      form.append("vehicle.id", this.vehicle.id);
      form.append("description", this.incident.description);
      form.append("type", this.incident.type);
      form.append("issueCategoryId", this.incident.categoryId);
      form.append("isUrgent", this.incident.isUrgent);

      if (!isNaN(this.incident.responsibleUser)) form.append("responsibleUser.id", this.incident.responsibleUser);
      if (this.incident.incidentResponsibleUserId)
        form.append("incidentResponsibleUserId", this.incident.incidentResponsibleUserId);

      Array.from(this.incident.files).forEach(x => {
        form.append("files", x, x.name);
      });

      this["incidents/add"](form)
        .then(() => {
          this.$bus.emit(UPDATE_INCIDENT_LIST);
          this.$bvModal.hide("new-incident-modal");
        })
        .catch(() => {
          alert(this.$t("new-incident-modal.wrong-create-incident"));
        })
        .finally(() => (this.isLoading = false));
    }
  },
  mounted() {
    this.$store.dispatch("issueCategories/load");
  }
};
</script>
