import http from "@/http.js";

export const issueCategories = {
  namespaced: true,
  state: {
    items: [],
    loadedIssueCategory: null,
    oldSelectedCutomChecks: []
  },
  mutations: {
    updateIssueCategories(state, payload) {
      state.items = payload;
    },
    setLoadedIssue(state, payload) {
      state.loadedIssueCategory = payload;
      state.oldSelectedCutomChecks = payload.customCheckIds;
    },
    setLoadedIssueTitle(state, payload) {
      state.loadedIssueCategory.title = payload;
    },
    setLoadedIssueShortTitle(state, payload) {
      state.loadedIssueCategory.shortTitle = payload.toUpperCase();
    },
    setLoadedIssueCustomCheckIds(state, payload) {
      // hold old ids
      state.loadedIssueCategory.customCheckIds = [...new Set([...state.oldSelectedCutomChecks, ...payload])];
    },
    setLoadedIssueDescription(state, payload) {
      state.loadedIssueCategory.description = payload;
    },
    clearLoadedIssueCategory(state) {
      state.loadedIssueCategory = null;
      state.oldSelectedCutomChecks = [];
    }
  },
  actions: {
    async load({ commit }) {
      let resp = await http.get("api/custom-checks/issue-categories");
      commit("updateIssueCategories", resp.data);
    },
    async add({ dispatch }, payload) {
      await http.post("api/custom-checks/issue-categories/add", payload);
      await dispatch("load");
    },
    async loadIssueCategory({ commit }, id) {
      let resp = await http.get(`api/custom-checks/issue-categories/${id}`);
      commit("setLoadedIssue", resp.data);
    },
    async updateLoadedIssueCategory({ state, dispatch }) {
      await http.post("api/custom-checks/issue-categories/update", state.loadedIssueCategory);
      dispatch("clearLoadedIssueCategory");
      await dispatch("load");
    },
    clearLoadedIssueCategory({ commit }) {
      commit("clearLoadedIssueCategory");
    }
  }
};
