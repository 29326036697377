import http from "@/http.js";

export const vehicleLoadAudit = {
  namespaced: true,
  actions: {
    getDriverActions(context, vehicleId) {
      return http.get(`api/vehicle-load-audit/${vehicleId}/driver-actions`);
    },
    getLoadActions(context, vehicleId) {
      return http.get(`api/vehicle-load-audit/${vehicleId}/load-actions`);
    },
    getDriversCurrentAcceptedVehicle() {
      return http.get("api/vehicle-load-audit/drivers-current-accepted-vehicle");
    },
    getDriverHomePageInfo() {
      return http.get("api/vehicle-load-audit/driver-home-page-info");
    }
  }
};
