import http from "@/http.js";
import api from "@/services/api.js";

export const goods = {
  namespaced: true,
  state: {
    goods: [],
    isLoading: false
  },
  mutations: {
    setGoodsLoadingState(state, isLoading) {
      state.isLoading = isLoading;
    },
    setGoodsList(state, goods) {
      state.goods = goods;
    }
  },
  actions: {
    loadGoodsShortInfo({ commit }) {
      commit("setGoodsLoadingState", true);
      return api
        .getGoodsShortInfo()
        .then(resp => commit("setGoodsList", resp.data))
        .finally(() => commit("setGoodsLoadingState", false));
    },
    getTitles() {
      return http.get("api/goods/titles");
    },
    getGoods(context, params) {
      return api.goods.find(params);
    },
    getGoodsById(context, goodsId) {
      return http.get(`api/goods/${goodsId}`);
    },
    getGoodsByGroup(context, { groupTitle, locale }) {
      return http.get(`api/goods/get-goods-by-group/${groupTitle}/locale/${locale}`);
    },
    getGroupsTitles() {
      return http.get("api/goods-group/titles");
    },
    getBaseInfoList() {
      return http.get("api/goods/all/base-info");
    },
    update(context, vm) {
      return http.post("api/goods/update", vm);
    },
    addProduct(context, vm) {
      return http.post("api/goods/add", vm);
    },
    remove(context, id) {
      return http.post(`api/goods/remove/${id}`);
    },
    getGoodsAugment(context, { goodsId, params }) {
      return http.post(`api/goods-augment/get-goods-payload-per-vehicle/${goodsId}`, params);
    },
    getAvailableSerialNumbers(context, goodsId) {
      return http.get(`api/goods/${goodsId}/serial-numbers/available`);
    },
    generateGoodsCode() {
      return api.generateGoodsCode();
    }
  }
};
