import api from "@/services/api.js";

export default {
  namespaced: true,
  state: {
    customChecks: [],
    isLoading: false
  },
  mutations: {
    setLoadingState(state, isLoading) {
      state.isLoading = isLoading;
    },
    setCustomChecks(state, customChecks) {
      state.customChecks = customChecks;
    }
  },
  actions: {
    loadData({ commit }) {
      commit("setLoadingState", true);
      return api
        .getCustomChecks()
        .then(resp => commit("setCustomChecks", resp.data))
        .finally(() => commit("setLoadingState", false));
    }
  }
};
