import api from "@/services/api.js";

export const appearanceSetting = {
  namespaced: true,
  state: {
    tableRowCountConfiguration: {
      common: 0,
      dashboard: 0
    }
  },
  getters: {
    commonTableRowCount: state => state.tableRowCountConfiguration.common,
    dashboardTableRowCount: state => state.tableRowCountConfiguration.dashboard
  },
  mutations: {
    setTableRowCountConfiguration(state, payload) {
      state.tableRowCountConfiguration = payload;
    },
    setCommonTableRowCount(state, payload) {
      state.tableRowCountConfiguration.common = payload;
    },
    setDashboardTableRowCount(state, payload) {
      state.tableRowCountConfiguration.dashboard = payload;
    }
  },
  actions: {
    getInitState() {
      return api.getTableRowCountConfiguration();
    },
    applyTableRowCountConfiguration({ state }) {
      return api.setTableRowCountConfiguration(state.tableRowCountConfiguration);
    },
    initState({ getters, dispatch, commit }) {
      if (getters.commonTableRowCount === 0 || getters.dashboardTableRowCount === 0) {
        return dispatch("getInitState").then(resp => commit("setTableRowCountConfiguration", resp.data));
      }
      return Promise.resolve();
    }
  }
};
