import Vue from "vue";
import VueRouter from "vue-router";
import auth from "@/services/auth.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: { needsAuthorization: false }
  },
  {
    path: "/no-role-home",
    name: "no-role-home",
    component: () => import("@/views/NoRoleHome.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/vehicle-management",
    name: "vehicle-management",
    component: () => import("@/views/VehicleManagement.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/goods-group",
    name: "goods-group",
    component: () => import("@/views/GoodsGroups.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/templates",
    name: "templates",
    component: () => import("@/views/Templates.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/goods",
    name: "goods",
    component: () => import("@/views/Goods.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/goods-loading",
    name: "goods-loading",
    component: () => import("@/views/GoodsLoading.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/Settings.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/administrator-home",
    name: "administrator-home",
    component: () => import("@/views/AdministratorHome.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/manager-home",
    name: "manager-home",
    component: () => import("@/views/ManagerHome.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/driver-home",
    name: "driver-home",
    component: () => import("@/views/DriverHome.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/incidents/current",
    name: "incident-open-list",
    component: () => import("@/views/incident/IncidentOpenList.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/incidents/history",
    name: "incident-history",
    component: () => import("@/views/incident/IncidentsHistory.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/incidents/:incidentId",
    name: "incident",
    component: () => import("@/views/Incident.vue")
  },
  {
    path: "/vehicle-load",
    name: "vehicle-load",
    component: () => import("@/views/VehicleLoad.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/current-loads",
    name: "current-loads",
    component: () => import("@/views/CurrentLoads.vue")
  },
  {
    path: "/current-load/:vehicleId",
    name: "current-load",
    component: () => import("@/views/CurrentLoad.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/vehicle-acceptance/:vehicleId",
    name: "vehicle-acceptance",
    component: () => import("@/views/vehicle/VehicleAcceptance.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/vehicle-checkout/:vehicleId",
    name: "vehicle-checkout",
    component: () => import("@/views/vehicle/VehicleCheckout.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/vehicle-events",
    name: "vehicle-events",
    component: () => import("@/views/vehicle/VehicleEvents.vue")
  },
  {
    path: "/my-settings",
    name: "my-settings",
    component: () => import("@/views/UserSettings.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/set-password/:changePasswordToken",
    name: "setting-new-password",
    component: () => import("@/views/SettingNewPassword.vue"),
    meta: { needsAuthorization: false }
  },
  {
    path: "/transactions",
    name: "transactions",
    component: () => import("@/views/Transactions.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/driver-transactions",
    name: "driver-transactions",
    component: () => import("@/views/DriverTransactions.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/sold-items-transactions",
    name: "sold-items-transactions",
    component: () => import("@/views/SoldItemsTransactions.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/serial-number-item/:serialNumberItemId",
    name: "serial-number-item",
    component: () => import("@/views/SerialNumberItem.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/inventory",
    name: "inventory",
    component: () => import("@/views/Inventory.vue"),
    meta: { needsAuthorization: true }
  },
  {
    path: "/load-history",
    name: "load-history",
    component: () => import("@/views/LoadHistory.vue"),
    meta: { needsAuthorization: true }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.needsAuthorization) {
    if (auth.isAuthorized() && auth.userRole() == null && to.name !== "no-role-home" && to.name !== "my-settings") {
      next("no-role-home");
    } else if (!auth.isAuthorized() && to.name !== "home") {
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
