import http from "@/http.js";

function encodeSearchParams(searchParams) {
  // serialize plain object into query params
  var encodedSearchParams = Object.keys(searchParams)
    .filter(x => searchParams[x])
    .map(x => `${x}=${encodeURIComponent(searchParams[x])}`)
    .join("&");
  return encodedSearchParams;
}

export default {
  // VEHICLE-LOAD
  unloadVehicle(vehicleId) {
    return http.post("api/vehicle-load/unload", { vehicleId });
  },
  changeLoadItem(vm) {
    return http.post("api/vehicle-load/change-load-item", vm);
  },
  setVehicleLoad(vm) {
    return http.post("api/vehicle-load/set", vm);
  },
  getLoadItemsByLoadId(id) {
    return http.get(`api/vehicle-load/load-items/by-load-id/${id}`);
  },
  getVehicleLoadItems(vehicleId) {
    return http.get(`api/vehicle-load/load-items/${vehicleId}`);
  },
  getLoadedGoods(vehicleId) {
    return http.get(`api/vehicle-load/${vehicleId}`);
  },
  // TRANSACTIONS
  searchTransactionsWithPages(vm) {
    return http.post("api/transactions/search", vm);
  },
  searchTransactions(params) {
    return http.get("api/transactions/search?" + encodeSearchParams(params));
  },
  findTransactionsByDriver(driverId, params) {
    return http.post(`api/transactions/by-driver/${driverId}/find`, params);
  },
  findTransactionsByGoods(goodsId, params) {
    return http.post(`api/transactions/by-goods/${goodsId}/find`, params);
  },
  closeTransactions(transactionsToCloseIds) {
    return http.post("api/transactions/close", transactionsToCloseIds);
  },
  exportTransactions(vm) {
    return http.post("api/transactions/export", vm, { responseType: "blob" });
  },
  exportLoadHistory(vm) {
    return http.post("api/transactions/export-load-history", vm, { responseType: "blob" });
  },
  getLoadHistory(params) {
    return http.post(`api/transactions/load-history`, params);
  },
  // SERIAL-NUMBER-ITEM
  searchSerialNumberItem(searchTerm) {
    return http.post("api/serial-number-item/search", { searchTerm });
  },
  getSerialNumberItemHistory(serialNumberItemId) {
    return http.get(`api/serial-number-item/${serialNumberItemId}/history`);
  },
  getSerialNumberItemInfo(serialNumberItemId) {
    return http.get(`api/serial-number-item/${serialNumberItemId}`);
  },
  getSerialNumberItemLocation(serialNumberItemId) {
    return http.get(`api/serial-number-item/${serialNumberItemId}/location`);
  },
  getSerialNumberItemStatus(serialNumberItemId) {
    return http.get(`api/serial-number-item/${serialNumberItemId}/status`);
  },
  setSerialNumberItemStatus(vm) {
    return http.post("api/serial-number-item/set-status", vm);
  },
  moveSerialNumberItemToWarehouse(serialNumberItemId) {
    return http.post("api/serial-number-item/move-to-warehouse", { id: serialNumberItemId });
  },
  moveSerialNumberItemToVehicle(serialNumberItemId, vehicleId) {
    return http.post("api/serial-number-item/change-location", { id: serialNumberItemId, vehicleId: vehicleId });
  },
  decommissionLoadItem(serialNumberItemId, reason) {
    return http.post("api/serial-number-item/decommission", { id: serialNumberItemId, reason: reason });
  },
  getAvailableSerialNumbers(goodsId) {
    return http.get(`api/goods/${goodsId}/serial-numbers/available`);
  },
  addSnItem(vm) {
    return http.post("api/serial-number-item/add", vm);
  },
  getSnItemsByGoodsInfoId(goodsInfoId) {
    return http.get(`api/serial-number-item/with-goods-id/${goodsInfoId}`);
  },
  // VEHICLE
  getAvailableVehicles() {
    return http.get("api/vehicle/available");
  },
  getAllVehicles(searchParams) {
    return http.get("api/vehicle/all?" + encodeSearchParams(searchParams));
  },
  getVehiclesRegistrationNumbers() {
    return http.get(`api/vehicles/registration-numbers`);
  },
  findVehicles(params) {
    return http.post("api/vehicle/find", params);
  },
  getVehicle(vehicleId) {
    return http.get(`api/vehicle/${vehicleId}`);
  },
  getVehicleByRegistrationNumber(registrationNumber) {
    return http.get(`api/vehicle?registrationNumber=${registrationNumber}`);
  },
  getVehicleCurrentLoadItems(vehicleId) {
    return http.get(`api/vehicle/${vehicleId}/current-load`);
  },
  getVehicleCurrentAcceptedLoad(vehicleId) {
    return http.get(`api/vehicle/${vehicleId}/current-accepted-load`);
  },
  searchVehicles(query) {
    return http.get(`api/vehicle/search?vehicle=${query}`);
  },
  searchVehicleEvents(searchParams) {
    return http.get("api/vehicle/events/search?" + encodeSearchParams(searchParams));
  },
  getVehiclesWithIncompleteLoads(searchParams) {
    return http.post("api/vehicle/with-incomplete-loads", searchParams);
  },
  getVehicleHistory(vehicleId, limit) {
    return http.get(`api/vehicle/${vehicleId}/history?limit=${limit}`);
  },
  //USER
  getUsersNamesByRole(role) {
    return http.get("api/users/names?" + encodeSearchParams({ role }));
  },
  getUsersNamesByRoles(roles) {
    let encoded = roles.map(x => encodeURIComponent(x));
    const paramName = "role=";
    var arrayAsString = "?" + paramName + encoded.join("&" + paramName);
    return http.get(`api/users/names${arrayAsString}`, roles);
  },
  getAllUsers() {
    return http.get("api/user/all/short-info");
  },
  getPanelSettings() {
    return http.get("api/user/panels");
  },
  updatePanelSetting(panelSetting) {
    return http.post("api/user/panel", panelSetting);
  },
  getUserSalesInfo() {
    return http.get("api/user/my-sales");
  },
  //GOODS
  getSaleableGoods() {
    return http.get(`api/goods/filter-by-saleable/${true}`);
  },
  getGoodsWithSn() {
    return http.get("api/goods/with-sn");
  },
  getGoodsWithoutSn() {
    return http.get(`api/goods/filter-by-serial/${false}`);
  },
  getGoodsIcon(iconId) {
    return http.get(`api/goods/icon/${iconId}`, { responseType: "arraybuffer" });
  },
  getGoodsImage(imageId) {
    return http.get(`api/goods/image/${imageId}`, { responseType: "arraybuffer" });
  },
  getGoodsShortInfo() {
    return http.get("api/goods");
  },
  getGoodsById(id) {
    return http.get(`api/goods/${id}`);
  },
  generateGoodsCode() {
    return http.get("api/goods/code/generate");
  },
  // GOODS-GROUPS
  getGoodsGroupsShortInfo() {
    return http.get("api/goods-group/groups/info");
  },
  // TEMPLATES
  getVehicleTemplate(vehicleId) {
    return http.get(`api/template/for-vehicle/${vehicleId}`);
  },
  // SETTING
  getConnectionString() {
    return http.get("api/settings/db/connection-string");
  },
  setConnectionString(connectionString) {
    return http.post("api/settings/db/set-connection-string", connectionString);
  },
  getTableRowCountConfiguration() {
    return http.get("api/settings/appearance/table-row-count-configuration");
  },
  setTableRowCountConfiguration(configuration) {
    return http.post("api/settings/appearance/set-table-row-count-configuration", configuration);
  },
  //DRIVER HOME
  getThumbnail(iconId) {
    return http.get(`api/incidents/thumbnail/${iconId}`, { responseType: "arraybuffer" });
  },
  getImage(imageId) {
    return http.get(`api/incidents/image/${imageId}`, { responseType: "arraybuffer" });
  },
  // CUSTOM-CHECKS
  getCustomCheck(customCheckId) {
    return http.get(`api/custom-checks/${customCheckId}`);
  },
  getCustomChecks() {
    return http.get("api/custom-checks");
  },
  getCustomChecksItemsTitles(locale) {
    return http.get(`api/custom-checks/items-titles/${locale}`);
  },
  addCustomCheck(vm) {
    return http.post("api/custom-checks/add", vm);
  },
  updateCustomCheck(vm) {
    return http.post("api/custom-checks/update", vm);
  },
  // I18N SETTINGS
  getUIStrings() {
    return Promise.all([
      http.get("api/i18n/ui-string/en"),
      http.get("api/i18n/ui-string/ru"),
      http.get("api/i18n/ui-string/lt")
    ]);
  },
  getUserStrings() {
    return http.get("api/i18n/user-string/all");
  },
  updateUIStrings(vm) {
    return http.post("api/i18n/ui-string/update", vm);
  },
  updateUserStrings(vm) {
    return http.post("api/i18n/user-string/update", vm);
  },
  incidents: {
    find(query) {
      return http.get("api/incidents/find?" + encodeSearchParams(query));
    },
    export(locale) {
      return http.get("api/incidents/open/all/export?" + encodeSearchParams({ locale }), { responseType: "blob" });
    }
  },
  events: {
    find(limit) {
      return http.get(`api/events/find?limit=${limit}`);
    }
  },
  goods: {
    find(params) {
      return http.post("api/goods/find", params);
    }
  }
};
