import http from "@/http.js";

export const template = {
  namespaced: true,
  actions: {
    getActualTemplates() {
      return http.get("api/template/actual");
    },
    findActualTemplates(context, params) {
      return http.post("api/template/actual/find", params);
    },
    findExpiredTemplates(context, params) {
      return http.post("api/template/expired/find", params);
    },
    getTemplateForVehicle(context, vehicleId) {
      return http.get(`api/template/for-vehicle/${vehicleId}`);
    },
    add(context, vm) {
      return http.post("api/template/add", vm);
    },
    update(context, vm) {
      return http.post("api/template/update", vm);
    },
    remove(context, vm) {
      return http.post("api/template/remove", vm);
    }
  }
};
