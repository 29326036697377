import http from "@/http.js";
import api from "@/services/api.js";

export const goodsGroup = {
  namespaced: true,
  state: {
    goodsGroups: [],
    isLoading: false
  },
  mutations: {
    setGoodsGroupsLoadingState(state, isLoading) {
      state.isLoading = isLoading;
    },
    setGoodsGroupsList(state, goodsGroups) {
      state.goodsGroups = goodsGroups;
    }
  },
  actions: {
    loadGoodsGroupsShortInfo({ commit }) {
      commit("setGoodsGroupsLoadingState", true);
      return api
        .getGoodsGroupsShortInfo()
        .then(resp => commit("setGoodsGroupsList", resp.data))
        .finally(() => commit("setGoodsGroupsLoadingState", false));
    },
    getGroupsInfo() {
      return http.get("api/goods-group/groups/info");
    },
    getGroup(context, { title, locale }) {
      return http.get(`api/goods-group/group/${title}/locale/${locale}`);
    },
    update(context, vm) {
      return http.post(`api/goods-group/group/update`, vm);
    },
    addGroup(context, vm) {
      return http.post("api/goods-group/add", vm);
    },
    remove(context, vm) {
      return http.post("api/goods-group/remove", vm);
    }
  }
};
